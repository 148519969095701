.App {
  text-align: center;
  font-family: Proxima Nova;
  font-weight: 300;
  line-height: 28px;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 800px;
  margin: auto;
}
.newPage {
  top: 0;
  left: 0;

  position: absolute;
  background-color: #282c34;
  z-index: 2;
}
img.returned-img {
  width: 100%;
}

.App-header p {
  font-size: 18px;
  padding: 0 16px;
}

.App-link {
  color: #61dafb;
}

.link-dip-cont {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 800px;
  grid-gap: 24px;
  margin-top: 24px;
  padding: 0 16px;
  box-sizing: border-box;
}

.link-dip-cont > * {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  padding: 20px;
  cursor: pointer;
  transition: 0.15s ease;
  box-sizing: border-box;
}
.link-dip-cont > *:hover {
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.05);
}

.img-wrap {
  height: 0;
  width: 100%;
  padding-bottom: 66.66%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.img-wrap img {
  border-radius: 5px;
  display: block;
  border: 3px solid #fff;
  transition: 0.15s ease;
  background: #fff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.24);
}
img.decktop-img {
  width: Calc(100% - 32px);
  position: absolute;
  left: 13px;
  top: 16px;
}
img.mob-img {
  width: 32%;
  position: absolute;
  top: 40%;
  right: 32px;
}

.link-dip-cont > *:hover img.decktop-img {
  transform: translate(-8px, -12px) rotate(-1deg);
}
.link-dip-cont > *:hover img.mob-img {
  transform: translate(12px, -12px) rotate(4deg);
}
.link-dip-cont svg {
  margin: auto;
  display: block;
  width: Calc(100% - 48px);
  max-width: 250px;
}

.divider {
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid white;
}

@media only screen and (max-width: 600px) {
  .link-dip-cont {
    display: grid;
    grid-template-columns: 1fr;
  }
}
